import form from 'Shared/Component/Form/Form';
import { findOne } from 'Shared/Helper/Dom/Dom';

const CLASSES = {
    'formExplanation': 'form__explanation',
};

const SELECTORS = {
    'formExplanation': `.${CLASSES.formExplanation}`,
};

class ReportListing extends form.controller {
    initialize () {
        super.initialize();

        this.bindMethodsToSelf([
            'onFormChange',
        ]);
    }

    connect () {
        super.connect();

        this.element.addEventListener('change', this.onFormChange);
    }

    onFormChange () {
        const issue = this.element.elements['report_listing_form[issue]'].value;
        const explanation = findOne(SELECTORS.formExplanation, this.element);

        explanation.hidden = !(issue === 'flag_a_report' || issue === 'other');
    }

    getRecaptchaElement () {
        return findOne('[data-report-listing-form-recaptcha-token]', this.element);
    }
}

export default {
    'name': 'report-listing-form',
    'controller': ReportListing,
};
