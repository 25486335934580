import { ComponentController } from 'Shared/Helper/Stimulus/ComponentController';
import { renderClasses } from 'Shared/Helper/Bem/Bem';
import { findOne } from 'Shared/Helper/Dom/Dom';

const NAME = 'listing-contact-info';

const CLASSES = {
    'showPhoneButton': renderClasses(NAME, 'link', ['show-phone']),
    'showEmailButton': renderClasses(NAME, 'link', ['show-email']),
    'emailLink': renderClasses(NAME, 'link', ['email']),
    'phoneLink': renderClasses(NAME, 'link', ['call']),
    'hiddenLink': renderClasses(NAME, 'link', ['hidden']),
};

const SELECTORS = {
    'showPhoneButton': `.${CLASSES.showPhoneButton}`,
    'showEmailButton': `.${CLASSES.showEmailButton}`,
    'emailLink': `.${CLASSES.emailLink}`,
    'phoneLink': `.${CLASSES.phoneLink}`,
    'dialog': 'dialog',
};

const MESSAGES = {
    'showExternalDialogClick': 'showExternalDialogClick',
    'requestPropertyAlertWizardDialog': 'requestPropertyAlertWizardDialog',
};

class ListingContactInfo extends ComponentController {
    onShowPhoneClick () {
        const showPhoneButton = findOne(SELECTORS.showPhoneButton, this.element);
        const phoneLink = findOne(SELECTORS.phoneLink, this.element);

        showPhoneButton.parentElement.removeChild(showPhoneButton);
        phoneLink.classList.remove(CLASSES.hiddenLink);

        this.trackEvent(showPhoneButton);
    }

    onShowEmailClick () {
        const showEmailButton = findOne(SELECTORS.showEmailButton, this.element);
        const emailLink = findOne(SELECTORS.emailLink, this.element);

        showEmailButton.parentElement.removeChild(showEmailButton);
        emailLink.classList.remove(CLASSES.hiddenLink);

        const notifyUrl = showEmailButton.getAttribute('data-notify-url');
        const listingId = showEmailButton.getAttribute('data-listing-id');
        const agentId = showEmailButton.getAttribute('data-agent-id');

        if (notifyUrl && listingId) {
            const formData = new FormData();
            formData.append('listingId', listingId);
            formData.append('agentId', agentId);

            navigator.sendBeacon(notifyUrl, formData);
        }
    }

    onShowExternalDialogClick (evt) {
        this.messageBus.postMessage({
            'message': MESSAGES.showExternalDialogClick,
        });

        this.trackEvent(evt.target);
    }

    onNotifyMeClick () {
        this.messageBus.postMessage({
            'message': MESSAGES.requestPropertyAlertWizardDialog,
        });
    }

    onSubscribeClick (evt) {
        this.trackEvent(evt.target);
        this.storeUnfinishedClickout(evt.target);
    }

    trackCtaClick (evt) {
        const link = evt.target;

        this.trackEvent(link);
    }

    trackEvent (element) {
        const notifyUrl = element.getAttribute('data-notify-url');
        const listingId = element.getAttribute('data-listing-id');
        const agentId = element.getAttribute('data-agent-id');

        if (notifyUrl && listingId) {
            const formData = new FormData();
            formData.append('listingId', listingId);

            if (agentId) {
                formData.append('agentId', agentId);
            }

            navigator.sendBeacon(notifyUrl, formData);
        }
    }

    storeUnfinishedClickout (element) {
        const notifyUrl = element.getAttribute('data-unfinished-clickout-url');
        const listingId = element.getAttribute('data-listing-id');
        const userId = element.getAttribute('data-user-id');

        if (notifyUrl && listingId && userId) {
            const formData = new FormData();
            formData.append('listingId', listingId);
            formData.append('userId', userId);

            navigator.sendBeacon(notifyUrl, formData);
        }
    }

    get componentName () {
        return NAME;
    }
}

export default {
    'name': NAME,
    'controller': ListingContactInfo,
};
