// libs
import { Application } from 'stimulus';

// shared components
import addressLookup from 'Shared/Component/Form/AddressLookup/AddressLookup';
import cancelSubscription from 'Shared/Component/Dialog/Presentation/CancelSubscription/CancelSubscription';
import form from 'Shared/Component/Form/Form';
import listingContactInfo from 'Shared/Component/ListingContactInfo/Presentation/ListingContactInfo';
import listingSearchItem from 'Shared/Component/ListingSearchItem/Presentation/ListingSearchItem';
import listingDetailDescription from 'Shared/Component/ListingDetailDescription/Presentation/ListingDetailDescription';
import mailAlertFromSearchDialog from 'Shared/Component/Dialog/MailAlertFromSearch/MailAlertFromSearch';
import mailAlertFromListingDialog from 'Shared/Component/Dialog/MailAlertFromListing/MailAlertFromListing';
import mapListingSummaryControl from 'Shared/Component/MapListingSummaryControl/MapListingSummaryControl';
import rentOut from 'Shared/Component/Form/RentOut/RentOut';
import searchControls from 'Shared/Component/SearchControls/Presentation/SearchControls';
import searchList from 'Shared/Component/SearchList/SearchList';
import textControl from 'Shared/Component/TextControl/TextControl';

// label components (should use shared)
import footer from 'Huurwoningen/Component/Footer/Footer';
import listingDetailActionbar from 'Huurwoningen/Component/ActionBar/ListingDetail/ListingDetailActionbar';
import loadingIndicator from 'Huurwoningen/Component/LoadingIndicator/LoadingIndicator';
import masthead from 'Huurwoningen/Component/Masthead/Presentation/Masthead';
import mediaMosaic from 'Huurwoningen/Component/MediaMosaic/MediaMosaic';
import quickAccessFilters from 'Huurwoningen/Component/QuickAccessFilters/QuickAccessFilters';
import reportListingForm from 'Huurwoningen/Component/Form/ReportListing/ReportListing';
import searchFilter from 'Huurwoningen/Component/SearchFilter/SearchFilter';
import searchFilters from 'Huurwoningen/Component/SearchFilters/SearchFilters';
import searchFiltersGroup from 'Huurwoningen/Component/SearchFiltersGroup/SearchFiltersGroup';
import searchFiltersModal from 'Huurwoningen/Component/Modal/SearchFilters/SearchFilters';
import searchListSorting from 'Huurwoningen/Component/SearchListSorting/SearchListSorting';

// pages
import listingDetailPage from 'Huurwoningen/Page/Listing/Detail/Detail';
import listingSearchPage from 'Huurwoningen/Page/Listing/Search/Search';
import manageSubscriptionPage from 'Huurwoningen/Page/Account/Subscription/Presentation/Manage';
import page from 'Huurwoningen/Page/Page';

const controllers = [
    addressLookup,
    cancelSubscription,
    form,
    footer,
    listingContactInfo,
    listingDetailActionbar,
    listingDetailDescription,
    listingDetailPage,
    listingSearchItem,
    listingSearchPage,
    loadingIndicator,
    mailAlertFromListingDialog,
    mailAlertFromSearchDialog,
    manageSubscriptionPage,
    mapListingSummaryControl,
    masthead,
    mediaMosaic,
    page,
    quickAccessFilters,
    rentOut,
    reportListingForm,
    searchControls,
    searchFilter,
    searchFilters,
    searchFiltersGroup,
    searchFiltersModal,
    searchList,
    searchListSorting,
    textControl,
];

window.stimulusApplication = Application.start();

controllers.forEach(controllerModule => {
    window.stimulusApplication.register(controllerModule.name, controllerModule.controller);
});
